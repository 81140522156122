/* global smplr */
import React, { useState } from 'react'

import useSmplrJs from './useSmplrJs'

import { desks, rooms } from './features'

let updateCamera = () => { };


const App = () => {
  useSmplrJs({ onLoad, dev: false })

  const defaultCamera = {
    alpha: -1.302427630355876,
    beta: 0.6555194619252502,
    radius: 23.91603633871978,
    target: { x: 146.014370014234, y: 1.5000000000000107, z: -100.26404234377125 },
    animate: true
  }

  const commonAreaCamera = {
    alpha: 2.0393365281579157,
    beta: 1.021763584294757,
    radius: 9.814884715161552,
    target: { x: 154.76772698956083, y: 1.5000000000000773, z: -91.46386626431601 },
    animate: true
  }

  const meetingRoom1Camera = {
    alpha: 0.7290040701030807,
    beta: 0.8386463794189677,
    radius: 7.730201592008731,
    target: { x: 149.26421883817747, y: 1.5000000000000773, z: -93.23387348523107 },
    animate: true
  }

  const meetingRoom2Camera = {
    alpha: -1.5938374825185204,
    beta: 0.14062620713571447,
    radius: 9.226792409195967,
    target: { x: 144.1096333740992, y: 1.5000000000000773, z: -96.75002865597517 },
    animate: true
  }

  const meetingRoom3Camera = {
    alpha: 0.7802823761810896,
    beta: 0.6555839402972596,
    radius: 7.66724742632184,
    target: { x: 140.25297293498014, y: 1.5000000000000702, z: -103.93155778272487 },
    animate: true
  }

  const [caCO2, setCaCO2] = useState('...');
  const [caTemp, setCaTemp] = useState('...');
  const [caHumid, setCaHumid] = useState('...');
  const [caLux, setCaLux] = useState('...');

  const [m1Temp, setM1Temp] = useState('...');
  const [m1Humid, setM1Humid] = useState('...');
  const [m1Lux, setM1Lux] = useState('...');
  const [m1Occ, setM1Occ] = useState('...');

  const [m2Temp, setM2Temp] = useState('...');
  const [m2Humid, setM2Humid] = useState('...');
  const [m2Lux, setM2Lux] = useState('...');
  const [m2Occ, setM2Occ] = useState('...');

  const [m3Temp, setM3Temp] = useState('...');
  const [m3Humid, setM3Humid] = useState('...');
  const [m3Lux, setM3Lux] = useState('...');
  const [m3Occ, setM3Occ] = useState('...');

  function onLoad() {
    const space = new smplr.Space({
      spaceId: '5e202a25-bbe8-4cd0-95a2-afc791b44597',
      clientToken: 'pub_eb760fee77634cdab2fe31146fc371c2',
      containerId: 'smplr-container'
    })
    space.startViewer({
      preview: false,
      mode: '3d',
      loadingMessage: "Loading Ilder Bergen Office",
      allowModeChange: true,
      hideNavigationButtons: true,
      renderOptions: {
        walls: {
          alpha: 0.40,
          maxHeightCm: 180
        }
      },
      cameraPlacement: {
        alpha: -1.302427630355876,
        beta: 0.6555194619252502,
        radius: 23.91603633871978,
        target: {
          x: 146.014370014234,
          y: 1.5000000000000107,
          z: -100.26404234377125
        },
      },
      onReady: () => {
        console.log('Viewer is ready')

        updateCamera = (angle => space.setCameraPlacement(angle))

        space.addDataLayer({
          id: 'desks',
          type: 'furniture',
          data: desks,
          tooltip: d => `${d.name} - ${d.reserved ? 'reserved' : (d.available ? 'free' : 'occupied')}`,
          color: d => (d.available ? (d.reserved ? '#4da6ff' : '#50b268') : '#f75e56')
        })

        space.addDataLayer({
          id: 'rooms',
          type: 'polygon',
          data: rooms,
          tooltip: d => `${d.name} - ${d.available ? 'free' : 'occupied'}`,
          color: d => (d.available ? '#3aa655' : '#ff3f34'),
          alpha: 0.7,
          height: 2.9
        })

        space.enablePickingMode({ onPick: obj => console.log(obj) })

        setInterval(() => {
          const req = new Request("https://mvp.digital-twin.ilderserv.no/api/sensors/?access_token=ilder-digital-twin", { method: 'GET', mode: 'cors' })
          fetch(req)
            .then(res => res.json())
            .then(res => {
              const sensDesk2Presence = res.data.sensors['eui-70b3d52c0001bbb6'] // Sensative Strip
              const sensDesk3Presence = res.data.sensors['eui-a81758fffe07f75b'] // EMSDesk
              const sensDesk4Presence = res.data.sensors['eui-a81758fffe074534'] // ERSDesk
              const sensDesk5Presence = res.data.sensors['eui-a81758fffe074533'] // ERSDesk
              const sensDesk6Presence = res.data.sensors['eui-a81758fffe07f75c'] // EMSDesk
              const sensDesk8Presence = res.data.sensors['eui-70b3d52c0001b9e8']
              const sensCommonCO2 = res.data.sensors['eui-a81758fffe06a054']
              const sensMeet1Occupancy = res.data.sensors['eui-a81758fffe063f47'] // ERSEye
              const sensMeet2Occupancy = res.data.sensors['eui-a81758fffe077522'] // ERS Eye
              const sensMeet3Occupancy = res.data.sensors['eui-a81758fffe0775dd'] // ERS Eye
              const sensIotlabOccupancy = res.data.sensors['eui-a81758fffe077520'] // ERS Eye
              const sensQuiet1Occupancy = res.data.sensors['eui-a81758fffe077521'] // ERS Eye
              const sensQuiet2Occupancy = res.data.sensors['eui-a81758fffe077524'] // ERS Eye
              const sensQuiet3Occupancy = res.data.sensors['eui-a81758fffe07751f'] // ERS Eye
              const sensQuiet4Occupancy = res.data.sensors['eui-a81758fffe077527'] // ERS Eye
              const sensQuiet5Occupancy = res.data.sensors['eui-a81758fffe077526'] // ERS Eye
              const sensRotundaOccupancy = res.data.sensors['eui-a81758fffe077525'] // ERS Eye

              //const deskQuiet1 = desks[0]
              //const deskQuiet2 = desks[1]
              //const deskQuiet3 = desks[2]
              //const deskQuiet4 = desks[3]
              //const deskQuiet5 = desks[4]
              //const desk1 = desks[5]
              const desk2 = desks[6]
              const desk3 = desks[7]
              const desk4 = desks[8]
              //const desk5 = desks[9]
              //const desk6 = desks[10]
              const desk7 = desks[11]
              const desk8 = desks[12]
              //const desk9 = desks[11]
              const desk10 = desks[14]
              //const desk11 = desks[15]
              //const desk12 = desks[16]
              //const deskExt1 = desks[17]
              //const deskExt2 = desks[18]
              //const deskExt3 = desks[19]
              //const deskExt4 = desks[20]
              //const deskExt5 = desks[21]
              //const deskExt6 = desks[22]
              //const deskExt7 = desks[23]
              //const deskExt8 = desks[24]
              //const deskExt9 = desks[25]
              //const deskExt10 = desks[26]
              //const deskExt11 = desks[27]
              //const deskExt12 = desks[28]
              //const deskExt13 = desks[29]
              //const deskExt14 = desks[30]

              const meet1 = rooms[0]
              const meet2 = rooms[1]
              const meet3 = rooms[2]
              const rotunda = rooms[3]
              const quiet1 = rooms[4]
              const quiet2 = rooms[5]
              const quiet3 = rooms[6]
              const quiet4 = rooms[7]
              const quiet5 = rooms[8]
              const iotlab = rooms[9]

              desk2.available = sensDesk2Presence.sensor_data.presence.value === 0
              desk3.available = sensDesk3Presence.sensor_data.occupancy.value === 0
              desk4.available = sensDesk4Presence.sensor_data.occupancy.value === 0
              desk7.available = sensDesk5Presence.sensor_data.occupancy.value === 0
              desk8.available = sensDesk6Presence.sensor_data.occupancy.value === 0
              desk10.available = sensDesk8Presence.sensor_data.presence.value === 0

              setCaCO2(sensCommonCO2.sensor_data.co2.value)
              setCaTemp(sensCommonCO2.sensor_data.temperature.value)
              setCaHumid(sensCommonCO2.sensor_data.humidity.value)
              setCaLux(sensCommonCO2.sensor_data.light.value)

              meet1.available = sensMeet1Occupancy.sensor_data.occupancy.value === 0
              setM1Temp(sensMeet1Occupancy.sensor_data.temperature.value)
              setM1Humid(sensMeet1Occupancy.sensor_data.humidity.value)
              setM1Lux(sensMeet1Occupancy.sensor_data.light.value)
              setM1Occ(meet1.available ? 'Free' : 'Occupied')

              meet2.available = sensMeet2Occupancy.sensor_data.occupancy.value === 0
              setM2Temp(sensMeet2Occupancy.sensor_data.temperature.value)
              setM2Humid(sensMeet2Occupancy.sensor_data.humidity.value)
              setM2Lux(sensMeet2Occupancy.sensor_data.light.value)
              setM2Occ(meet2.available ? 'Free' : 'Occupied')

              meet3.available = sensMeet3Occupancy.sensor_data.occupancy.value === 0
              setM3Temp(sensMeet3Occupancy.sensor_data.temperature.value)
              setM3Humid(sensMeet3Occupancy.sensor_data.humidity.value)
              setM3Lux(sensMeet3Occupancy.sensor_data.light.value)
              setM3Occ(meet3.available ? 'Free' : 'Occupied')

              rotunda.available = sensRotundaOccupancy.sensor_data.occupancy.value === 0
              iotlab.available = sensIotlabOccupancy.sensor_data.occupancy.value === 0
              quiet1.available = sensQuiet1Occupancy.sensor_data.occupancy.value === 0
              quiet2.available = sensQuiet2Occupancy.sensor_data.occupancy.value === 0
              quiet3.available = sensQuiet3Occupancy.sensor_data.occupancy.value === 0
              quiet4.available = sensQuiet4Occupancy.sensor_data.occupancy.value === 0
              quiet5.available = sensQuiet5Occupancy.sensor_data.occupancy.value === 0
            })

          space.updateDataLayer({
            id: 'desks',
            type: 'furniture',
            data: desks,
            tooltip: d => `${d.name} - ${d.reserved ? 'reserved' : (d.available ? 'free' : 'occupied')}`,
            color: d => (d.available ? (d.reserved ? '#4da6ff' : '#50b268') : '#f75e56')
          })

          space.updateDataLayer({
            id: 'rooms',
            type: 'polygon',
            data: rooms,
            tooltip: d => `${d.name} - ${d.available ? 'free' : 'occupied'}`,
            color: d => (d.available ? '#3aa655' : '#ff3f34'),
          })
        }, 1000)
      },
      onError: error => console.error('Could not start viewer', error)
    })
  }

  const query = new URLSearchParams(window.location.search)
  const key = query.get("key")
  if (key !== "09021d97d8511034397e0e61911ac4a2a0b07243eb81715662f51bd2a606fe8b") {
    return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <h1>Access Denied</h1>
      <div id='smplr-container' style={{visibility: 'hidden'}}></div>
    </div>
    )
  }

  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <div className='wrapper'>
        <div id='smplr-container'
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'whitesmoke'
          }}></div>
        <iot-sidebar onMouseLeave={() => updateCamera(defaultCamera)}>
          <iot-room onMouseEnter={() => updateCamera(commonAreaCamera)}>
            <room-name>Common Area</room-name>
            <sensor-readings>
              <sensor-reading sensor-type="co2"><p>{caCO2} ppm</p></sensor-reading>
              <sensor-reading sensor-type="temperature"><p>{caTemp} &deg;C</p></sensor-reading>
              <sensor-reading sensor-type="humidity"><p>{caHumid} %</p></sensor-reading>
              <sensor-reading sensor-type="brightness"><p>{caLux} lux</p></sensor-reading>
            </sensor-readings>
          </iot-room>

          <iot-room onMouseEnter={() => updateCamera(meetingRoom1Camera)}>
            <room-name>Meeting Room 1</room-name>
            <sensor-readings>
              <sensor-reading sensor-type="occupancy"><p>{m1Occ}</p></sensor-reading>
              <sensor-reading sensor-type="temperature"><p>{m1Temp} &deg;C</p></sensor-reading>
              <sensor-reading sensor-type="humidity"><p>{m1Humid} %</p></sensor-reading>
              <sensor-reading sensor-type="brightness"><p>{m1Lux} lux</p></sensor-reading>
            </sensor-readings>
          </iot-room>

          <iot-room onMouseEnter={() => updateCamera(meetingRoom2Camera)}>
            <room-name>Meeting Room 2</room-name>
            <sensor-readings>
              <sensor-reading sensor-type="occupancy"><p>{m2Occ}</p></sensor-reading>
              <sensor-reading sensor-type="temperature"><p>{m2Temp} &deg;C</p></sensor-reading>
              <sensor-reading sensor-type="humidity"><p>{m2Humid} %</p></sensor-reading>
              <sensor-reading sensor-type="brightness"><p>{m2Lux} lux</p></sensor-reading>
            </sensor-readings>
          </iot-room>

          <iot-room onMouseEnter={() => updateCamera(meetingRoom3Camera)}>
            <room-name>Meeting Room 3</room-name>
            <sensor-readings>
              <sensor-reading sensor-type="occupancy"><p>{m3Occ}</p></sensor-reading>
              <sensor-reading sensor-type="temperature"><p>{m3Temp} &deg;C</p></sensor-reading>
              <sensor-reading sensor-type="humidity"><p>{m3Humid} %</p></sensor-reading>
              <sensor-reading sensor-type="brightness"><p>{m3Lux} lux</p></sensor-reading>
            </sensor-readings>
          </iot-room>
        </iot-sidebar>
      </div>
    </div>
  )
}

export default App