export const desks = [
  {
    name: 'Quiet Room 1',
    furnitureId: 'c6f51b0a-722d-4ecc-aba5-0b1243ccb97a',
    available: true,
    reserved: false
  },
  {
    name: 'Quiet Room 2',
    furnitureId: '2135a046-8a5e-4918-a7f6-2bc07b3d6389',
    available: true,
    reserved: false
  },
  {
    name: 'Quiet Room 3',
    furnitureId: 'd2b1b268-1337-4f24-8c1d-cbcb740aa38f',
    available: true,
    reserved: false
  },
  {
    name: 'Quiet Room 4',
    furnitureId: 'b94cd418-f2b9-4752-89ee-46ec99203f7f',
    available: true,
    reserved: false
  },
  {
    name: 'Quiet Room 5',
    furnitureId: 'e671feb3-4e73-4560-b20e-a83d8728332d',
    available: true,
    reserved: false
  },
  {
    name: 'Desk 1',
    furnitureId: '57d25404-bbfe-4dc5-a16e-bad5cf8da5ec',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 2',
    furnitureId: '6bd671bc-ce4d-4305-a8ed-a841589cf67f',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 3',
    furnitureId: '82596dd2-4c25-4505-9475-9efb6edba2ac',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 4',
    furnitureId: 'e8692708-2b5f-4525-a56e-48399c8846e5',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 5',
    furnitureId: 'a31825f6-1d28-4e12-895f-cb5ad75e6962',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 6',
    furnitureId: 'babe3443-371d-490c-bb0b-592fafbe16ba',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 7',
    furnitureId: '5d85fb42-915c-4ea8-9489-f75efbf5ff69',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 8',
    furnitureId: 'fc4a3f48-3df2-4a3f-b2aa-94d65edcbb5f',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 9',
    furnitureId: 'c2fc2472-6059-425a-92a4-d592b1c941cb',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 10',
    furnitureId: '3902a002-3c5c-439a-932c-e0eef5074657',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 11',
    furnitureId: 'f403a347-c8cc-4bd1-9838-4e251c8c0563',
    available: true,
    reserved: true
  },
  {
    name: 'Desk 12',
    furnitureId: '304ebc59-e363-4277-8c53-7f2a1abc9a39',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 1',
    furnitureId: '4cda6bbc-6599-4d50-9b2b-2a59b6436607',
    available: true,
    reserved: false
  },
  {
    name: 'Ext Desk 2',
    furnitureId: '8f3eb259-f1a7-4118-b480-206575fb58d8',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 3',
    furnitureId: 'dd94de63-ff1c-4df7-a8d6-f42ae148f906',
    available: true,
    reserved: false
  },
  {
    name: 'Ext Desk 4',
    furnitureId: '356a2050-c1f6-44e0-8159-f3f220fb6b27',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 5',
    furnitureId: 'e4b14231-73e3-4aab-aacc-6ab6655e9785',
    available: true,
    reserved: false
  },
  {
    name: 'Ext Desk 6',
    furnitureId: 'e38403e4-4ddf-498e-a5c5-4797554c4ac4',
    available: true,
    reserved: false
  },
  {
    name: 'Ext Desk 7',
    furnitureId: '751a8d04-702c-4e6d-bc9d-8798fc602e50',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 8',
    furnitureId: '9bed0100-b909-40d1-9227-c7d0235a42ff',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 9',
    furnitureId: 'd6799777-f2c2-4d8f-b338-4af7f207072b',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 10',
    furnitureId: 'b4a38bd7-af10-4b92-b970-752a92f3f93a',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 11',
    furnitureId: 'd82a033e-5e52-4c22-917b-72accf1249b9',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 12',
    furnitureId: 'a6502d28-6fc6-4e50-ba54-92c22e4ffeda',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 13',
    furnitureId: '32ca0e90-bc40-4a31-8c63-e40c5dc8e943',
    available: true,
    reserved: true
  },
  {
    name: 'Ext Desk 14',
    furnitureId: '6c1096e2-837e-4ecc-be44-8a55c5d3cec6',
    available: true,
    reserved: true
  },
]

export const rooms = [
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91c7',
        name: 'Meeting Room 1',
        available: true,
        reserved: false,
        coordinates: [
          { elevation: 0.019833127903944092, levelIndex: 0, x: 150.52224204807916, z: -93.7967916217857 },
          { elevation: 0.0020000000949949026, levelIndex: 0, x: 147.52063656938887, z: -95.40039429868128 },
          { elevation: 1.7999999523162842, levelIndex: 0, x: 145.77139339824734, z: -92.32437622492371 },
          { elevation: 1.7999999523162844, levelIndex: 0, x: 148.88280254304058, z: -90.64900261484331 }, 
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91c8',
        name: 'Meeting Room 2',
        available: true,
        reserved: false,
        coordinates: [
          { elevation: 0.0020000000949931263, levelIndex: 0, x: 142.09764451049767, z: -94.68233509035242 },
          { elevation: 0.002000000094995791, levelIndex: 0, x: 146.95677598119062, z: -94.6881355655003 },
          { elevation: 0.002000000094995791, levelIndex: 0, x: 147.9612642065093, z: -96.50627940666548 },
          { elevation: 0.0020000000949949026, levelIndex: 0, x: 147.9543841267513, z: -98.55263015914771 },
          { elevation: 0.002000000094995791, levelIndex: 0, x: 142.09596352851796, z: -98.55890325178007 },
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91c9',
        name: 'Meeting Room 3',
        available: true,
        reserved: false,
        coordinates: [
          { elevation: 0.002000000094995791, levelIndex: 0, x: 137.97695541434032, z: -105.532554657908 },
          { elevation: 0.002000000094995791, levelIndex: 0, x: 137.96200624519224, z: -101.53851582062562 },
          { elevation: 0.01891398579179615, levelIndex: 0, x: 140.52346029208678, z: -101.53557550563114 },
          { elevation: 0.13629989176783264, levelIndex: 0, x: 140.49614409836963, z: -105.55324592659962 },
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91ca',
        name: 'Rotunda',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 157.64431154219193, z: -99.2568225734145, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 158.00668651863808, z: -99.22772788054465, elevation: 0.002000000094996679},
          {levelIndex: 0, x: 158.36159570453327, z: -99.24822818867752, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 158.88326880854382, z: -99.34068362796846, elevation: 0.009959982181865001},
          {levelIndex: 0, x: 159.29729753860462, z: -99.50364591535936, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 159.74001406069465, z: -99.77971507504753, elevation: 0.002000000094996679},
          {levelIndex: 0, x: 160.0828371637566, z: -100.08601049781889, elevation: 0.04265849384613141},
          {levelIndex: 0, x: 160.4233029625604, z: -100.53102474774929, elevation: 0.0020000000949931263},
          {levelIndex: 0, x: 160.7515928095509, z: -101.46168399383066, elevation: 1.248073417457542},
          {levelIndex: 0, x: 160.7893303241251, z: -101.84982256022836, elevation: 1.4239761723534672},
          {levelIndex: 0, x: 160.76637123361326, z: -102.31252343201515, elevation: 1.6698435219858654},
          {levelIndex: 0, x: 160.622751848718, z: -102.84123465520038, elevation: 0.6137595901558965},
          {levelIndex: 0, x: 159.94149935114763, z: -103.87681569307327, elevation: 0.8999999761581412},
          {levelIndex: 0, x: 159.114342649754, z: -104.41613308135598, elevation: 0.5239309526512148},
          {levelIndex: 0, x: 158.55150697478118, z: -104.59059141208907, elevation: 0.40240433298199996},
          {levelIndex: 0, x: 157.7467129767539, z: -104.61539417504872, elevation: 0.29057529980348207},
          {levelIndex: 0, x: 156.70529031287393, z: -104.35010202470684, elevation: 1.4144405637287134},
          {levelIndex: 0, x: 156.2260263191571, z: -103.97896067633901, elevation: 0.23693965877156842},
          {levelIndex: 0, x: 155.91555704372445, z: -103.62933654165263, elevation: 0.0020000000949931263},
          {levelIndex: 0, x: 155.647202678603, z: -103.22590003519515, elevation: 0.011833948984145692},
          {levelIndex: 0, x: 155.45419702772043, z: -102.72985565688383, elevation: 0.028466569785429385},
          {levelIndex: 0, x: 155.36817338194342, z: -102.32977486532675, elevation: 0.027817839495794416},
          {levelIndex: 0, x: 155.35215763792027, z: -101.9760330921777, elevation: 0.002000000094996679},
          {levelIndex: 0, x: 155.36980786187445, z: -101.56418558929344, elevation: 0.051417713649760444},
          {levelIndex: 0, x: 155.43859653096683, z: -101.28577319878897, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 155.88992615323158, z: -100.4440551477814, elevation: 0.0020000000949931263},
          {levelIndex: 0, x: 156.19598289970043, z: -100.08473532326506, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 156.52625794526296, z: -99.79580681550436, elevation: 0.002000000094995791},
          {levelIndex: 0, x: 156.86758284413776, z: -99.57259776774922, elevation: 0.0020000000949953467}
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91cb',
        name: 'Quiet Room 1',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 151.6753199601909, z: -83.9681313916807, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 153.2041284561975, z: -83.15093315506496, elevation: 0.0020000000949949026},
          {levelIndex: 0, x: 154.45355503182412, z: -85.35551808316082, elevation: 0.3765595541261977},
          {levelIndex: 0, x: 152.97163962867853, z: -86.31114667126413, elevation: 0.0020000000949940144}
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91cc',
        name: 'Quiet Room 2',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 151.52970334459616, z: -84.05033796660545, elevation: 0.002000000094995791},
          {levelIndex: 0, x: 152.82267116056653, z: -86.39387231236209, elevation: 0.002000000094995791},
          {levelIndex: 0, x: 151.32610606949086, z: -87.226586539636, elevation: 0.0054482890406752915},
          {levelIndex: 0, x: 150.055022084613, z: -84.8596767883342, elevation: 0.7476600214000788}
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91cd',
        name: 'Quiet Room 3',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 150.16708690930528, z: -85.41948776300106, elevation: 0.0020000000949953467},
          {levelIndex: 0, x: 151.1838459041628, z: -87.2837846813077, elevation: 1.6911976483745677},
          {levelIndex: 0, x: 149.68678521543927, z: -88.1290835676575, elevation: 0.002000000094995791},
          {levelIndex: 0, x: 148.61008030090363, z: -86.23260589875022, elevation: 0.026469571255132163}
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91ce',
        name: 'Quiet Room 4',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 147.95879063101916, z: -98.73932688189069, elevation: 0.0020000000949984553},
          {levelIndex: 0, x: 147.9500049966283, z: -100.34202151935673, elevation: 0.06790276033957632},
          {levelIndex: 0, x: 146.48708965907278, z: -100.34202151935673, elevation: 0.2115391134408089},
          {levelIndex: 0, x: 145.9824327832746, z: -99.66803905712099, elevation: 0.15388601817211622},
          {levelIndex: 0, x: 146.0141843618182, z: -98.74889311463033, elevation: 0.35781856474911145}
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91cf',
        name: 'Quiet Room 5',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 143.97113874542828, z: -98.78099585209941, elevation: 1.5319972671612043},
          {levelIndex: 0, x: 144.02898063554088, z: -99.6575239571431, elevation: 0.2570314514984271},
          {levelIndex: 0, x: 143.60864483593656, z: -100.4333560902633, elevation: 0.014978770161638177},
          {levelIndex: 0, x: 142.09204815884087, z: -100.4314596020918, elevation: 0.002000000094995791},
          {levelIndex: 0, x: 142.1030537113919, z: -98.72411347648925, elevation: 0.0020000000949949026}
        ]
    },
    {
        id: 'c92d5ab7-ed65-5e01-9bb1-87c3936f91d0',
        name: 'IoT Lab',
        available: true,
        reserved: false,
        coordinates: [
          {levelIndex: 0, x: 148.84086159946133, z: -90.62084346905212, elevation: 0.12281526888002858},
          {levelIndex: 0, x: 145.8193006959689, z: -92.25253695202338, elevation: 0.01660756857250867},
          {levelIndex: 0, x: 144.5987755398801, z: -89.97333518285905, elevation: 0.002000000094996679},
          {levelIndex: 0, x: 147.63807522594828, z: -88.34781796091796, elevation: 0.002000000094995791}
        ]
    },
]










